* {
  font-family: libre franklin, sans-serif;
  font-weight: 100;
  font-style: normal;
}
footer {
  position: fixed;
  bottom: 5%;
  width: 100%;
}

.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly
}

.navbarButton {
  color: #000000 !important;
  background-color: #ffffff !important;
  font-weight: "bold" !important;
}

.signout-auto {
  color: #000000 !important;
  background-color: #ffffff !important;
  font-weight: "normal" !important;
}

.signin-auto {
  color: #0074ff !important;
  background-color: #ffffff !important;
  font-weight: "normal" !important;
}


.navbar-brand {
  color: #0074ff !important;
}

.navbarStyle {
  color: #0074ff !important;
  background-color: #fff !important;
  border-bottom: 1.5px solid black;
}

.componentsStyle {
  background-color: #fff !important;
  padding: .5rem 1rem !important;
}


.table-responsive {
  max-height: 39rem !important;
}

.tableColumn {
  word-break: break-all
}

.button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 100%;
  align-items: center;
}

button.button:disabled {
  opacity: 0.3;
}

.center {
  margin: auto;
  width: 75%;
  padding: 10px;
}

.logo_bottom {
  position: absolute;
  right: 100px;
  bottom: 100px;
}

.draganddrop {
  border:2px dashed rgb(101, 101, 101);
  height: 100%;
  padding: 10px;
  margin: auto;
}

.uploadButton {
  margin: auto;
  width: 100%;
  padding: 1px;
}

.runAnalysisButton {
  margin: auto;
  width: 100%;
  padding: 0px;
}

.iconText {
  margin: 0 .5rem;
}

.profileButton {
  margin: .5rem .5rem;
}

.oncobitLogo img {
  width: 200px;
  padding: auto;
}

.oncobitFavicon img {
  width: 40px; /* Adjust the width of your logo image */
  height: 40px;
}

.ag-theme-quartz {
  --ag-foreground-color: rgb(0, 0, 0);
  --ag-background-color: rgb(255, 255, 255);
  --ag-header-foreground-color: rgb(255, 255, 255);
  --ag-header-background-color: #0074ff !important;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(255, 255, 255);

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}

.welcome-text {
  font-size: 20px; /* Increase the font size */
  line-height: 1.6; /* Improve readability */
  color: #000000; /* Darker text color for better contrast */
}

.content-page {
  max-width: 144rem;
  padding-left: 9rem;
  padding-right: 9rem;
  margin-left: auto;
  margin-right: auto;
}

.sameRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 550px;
  padding: .05rem 0.25rem !important;
}

.fileShare { 
  flex: 1; 
  margin-right:0px;
  margin-left:0px;
  padding-left: 0rem;
  padding-right: 0rem;
} 

.fileUpload { 
  flex: 0.2; 
  margin-right:0px;
  margin-left:0px;
  padding-left: 0rem;
  padding-right: 0rem;
} 

.pmanal-img {
  width: 100%;
}

.pmanal-text {
  white-space: nowrap;
  width: 100%;
}

.trash-can {
  height: 100%
}

.button-section {
  width: 100%;
}

.button-section2 {
  width: 100%;
  margin-top: 2%;
}


.pmanal-overlay {
  display: flex;
  flex-direction: column;
  height: 100px;
}

.overlay-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
  align-items: center;
  font-size: 80%;
}


.alignButtons {
  display: flex;
  flex-direction: row;
  width: 20%;
  gap: 25px;
  position: relative;
}


.align {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

 /* The switch - the box around the slider */
 .switch {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0074ff !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0074ff !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 

.trash-icon {
  height: 100%
}

.container {
  width: 12rem;
}

.slider {
  display: flex;
  flex-direction: column;
}

label {
  margin: 0 auto;
}